import React from 'react';

import {
    grid,
    contentWrapper,
    title as titleClass,
    description as descriptionClass,
    button as buttonClass,
    layout,
    modalContent,
    team as teamClass
} from './quote-summary.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import Content from '../atoms/content';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

export interface IQuoteSummaryProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function QuoteSummary({ TitleTag, section, className = '' }: IQuoteSummaryProps) {
    const { sectionId, content, css, style } = section;
    const { texts, buttons } = content;

    const [title, team, description] = texts;
    const { text: buttonText, url: buttonUrl } = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${layout}`}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={contentWrapper}>
                {title && (
                    <Content className={titleClass}>
                        <Markdown>{title}</Markdown>
                    </Content>
                )}
                <div className={modalContent}>
                    {description && (
                        <Content className={descriptionClass}>
                            <Markdown>{description}</Markdown>
                        </Content>
                    )}
                    {team && (
                        <Content className={teamClass}>
                            <Markdown>{team}</Markdown>
                        </Content>
                    )}
                    {buttonText && buttonUrl && (
                        <Button as="link" className={buttonClass} to={buttonUrl} stylePreset="cta">
                            {buttonText}
                        </Button>
                    )}
                </div>
            </div>
        </Section>
    );
}
